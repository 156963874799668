import { z } from "zod";
import { api } from ".";
import { useQuery, UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";

interface Reference {
  name: string;
  phone: string;
  email: string;
  relationship: string;
  company: string;
  title: string;
  additional_information: string;
}

export const REFERENCES_BASE_URL = "references";

export const referenceSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  relationship: z.string(),
  company: z.string(),
  title: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  additional_information: z.string(),
});

export type ReferenceData = z.infer<typeof referenceSchema>;

export type ReferenceCreateParams = Pick<
  ReferenceData,
  "name" | "email" | "phone" | "relationship" | "company" | "title"
>;
export type ReferenceUpdateParams = Partial<ReferenceData>;

// API queries
export const getReferences = async (): Promise<ReferenceData[]> => {
  const result = await api.get(REFERENCES_BASE_URL);
  return result.data;
};

export const createReference = async (reference: ReferenceCreateParams): Promise<ReferenceData> => {
  const result = await api.post(REFERENCES_BASE_URL, { reference });
  return referenceSchema.parse(result.data);
};

export const updateReference = async (reference: ReferenceUpdateParams): Promise<ReferenceData> => {
  const result = await api.put(`${REFERENCES_BASE_URL}/${reference.id}`, { reference });
  return referenceSchema.parse(result.data);
};

export const deleteReference = async (id: number): Promise<void> => {
  await api.delete(`${REFERENCES_BASE_URL}/${id}`);
};

// React Query hooks
const invalidateReferences = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([REFERENCES_BASE_URL]);
};

export const useReferencesQuery = (): UseQueryResult<ReferenceData[]> => {
  return useQuery({ queryKey: [REFERENCES_BASE_URL], queryFn: () => getReferences() });
};

export const useCreateReferenceMutation = () => {
  return useMutation(createReference, {
    onSuccess: invalidateReferences(),
  });
};

export const useUpdateReferenceMutation = () => {
  return useMutation(updateReference, {
    onSuccess: invalidateReferences(),
  });
};

export const useDeleteReferenceMutation = () => {
  return useMutation(deleteReference, {
    onSuccess: invalidateReferences(),
  });
};

export const findCandidateByEmail = async (email: string): Promise<any> => {
  try {
    const response = await api.get(
      `${REFERENCES_BASE_URL}/find_candidate_by_email?email=${encodeURIComponent(email)}`
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message || "Failed to find candidate");
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const createCandidateRecord = async (
  candidateId: string,
  reference: Reference
): Promise<any> => {
  const payload = {
    reference: {
      candidate_id: candidateId,
      name: reference.name,
      phone: reference.phone,
      email: reference.email,
      relationship: reference.relationship,
      company: reference.company,
      title: reference.title,
      additional_information: reference.additional_information,
    },
  };

  try {
    const response = await api.post(`${REFERENCES_BASE_URL}/create_references_in_home`, payload);
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(error.message || "Failed to create candidate record");
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};
