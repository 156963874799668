import { z } from "zod";

export const MODEL_TYPE = "users";

export const userSchema = z.object({
  id: z.number(),
  name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  picture_url: z.string().nullable().optional(),
  references_submitted: z.boolean(),
});

export type UserApiData = z.infer<typeof userSchema>;

export const currentUserSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  email: z.string().email(),
  picture_uri: z.string().url(),
  references_submitted: z.boolean(),
});

export type CurrentUserApiData = z.infer<typeof currentUserSchema>;
