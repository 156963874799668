import React, { useEffect, useState } from "react";
import {
  useToast,
  useColorModeValue,
  VStack,
  Flex,
  Card,
  Heading,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReferenceData, useReferencesQuery, useUpdateReferenceMutation } from "../api/reference";
import { ReferenceCard } from "./ReferenceCard";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import { Text } from "@chakra-ui/react";
import { useCurrentUserQuery } from "../api/user/queries";
import { findCandidateByEmail, createCandidateRecord } from "../api/reference";

export const ReferencesPage = () => {
  const { data: initialReferences } = useReferencesQuery();
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUserQuery();
  const [isEditable, setIsEditable] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: updateReference } = useUpdateReferenceMutation();
  const [validReferencesCount, setValidReferencesCount] = useState(0);
  const [references, setReferences] = useState<ReferenceData[]>([]);

  useEffect(() => {
    if (currentUser) {
      setIsEditable(!currentUser.references_submitted);

      if (initialReferences) {
        setReferences(initialReferences);
        updateValidReferencesCount(initialReferences);
      }
    }
  }, [initialReferences, currentUser]);

  const updateValidReferencesCount = (references: ReferenceData[]) => {
    const validCount = references.filter((reference) => {
      return isValidEmail(reference.email) || isValidPhoneNumber(reference.phone);
    }).length;
    setValidReferencesCount(validCount);
  };

  const handleEditReference = (index: number, updatedReference: ReferenceData) => {
    const newReferences = [...references];
    newReferences[index] = updatedReference;
    setReferences(newReferences);
    updateValidReferencesCount(newReferences);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^(\(\d{3}\)\s?|\d{3}-?|\d{3})?\d{3}-?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const validateReferences = (
    references: ReferenceData[]
  ): { isValid: boolean; validReferences: ReferenceData[] } => {
    let validReferences: ReferenceData[] = [];
    const errors: string[] = [];

    references.forEach((reference, index) => {
      const hasEssentialInfo =
        reference.name.trim().length > 0 && reference.title.trim().length > 0;
      const isContactInfoProvided =
        isValidEmail(reference.email.trim()) || isValidPhoneNumber(reference.phone.trim());

      if (hasEssentialInfo && isContactInfoProvided) {
        validReferences.push(reference);
      } else {
        if (index < 2) {
          if (!hasEssentialInfo) {
            errors.push(`Name and Title for Reference ${index + 1} are required.`);
          }
          if (!isContactInfoProvided) {
            errors.push(`Valid Email or Phone Number for Reference ${index + 1} is required.`);
          }
        }
      }
    });

    if (errors.length > 0) {
      toast({
        title: "Validation Errors",
        description: `Please correct the following: ${errors.join(", ")}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return { isValid: false, validReferences: [] };
    }

    return { isValid: validReferences.length >= 2, validReferences };
  };

  const handleSubmit = async () => {
    try {
      if (!currentUser) {
        throw new Error("User information is not available");
      }

      const { isValid, validReferences } = validateReferences(references);
      if (!isValid) return; // Proceed only if at least 2 references are valid

      const candidate = await findCandidateByEmail(currentUser.email);

      // Assuming updateReference is an async function
      const updateOps = references.map((reference) => updateReference(reference));
      await Promise.all(updateOps);

      const createRecs = validReferences.map((reference) =>
        createCandidateRecord(candidate.data.id, reference)
      );
      await Promise.all(createRecs);

      toast({
        title: "References Submitted",
        description: "We will be reaching out to your references shortly.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/");
      setIsEditable(false);
    } catch (error) {
      let message = "An error occurred.";
      if (error instanceof Error) {
        message = error.message;
      }

      toast({
        title: "Error",
        description: message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="top">
      <Card variant={"elevated"} maxW="3xl">
        <CardBody>
          <VStack spacing={1} align="left" mb={4}>
            <Heading size="lg" fontWeight={"light"}>
              Submit your Professional References
            </Heading>
            {isEditable ? (
              <>
                <Text fontWeight={"light"} py="2">
                  We are looking forward to reaching out to your references. Please provide us with
                  2-3 professional references, at least one of whom was your supervisor. Be sure to
                  include either an email address or phone number for each.
                </Text>
                <Text color="gray.500" fontSize="sm" alignSelf="flex-start">
                  <span style={{ color: "red" }}>*</span> Denotes required fields
                </Text>
                <VStack
                  spacing={0}
                  mb={4}
                  overflow={"hidden"}
                  border={"1px"}
                  borderRadius="md"
                  borderColor={useColorModeValue("gray.100", "gray.700")}>
                  {references?.map((reference, index) => (
                    <ReferenceCard
                      key={reference.id}
                      reference={reference}
                      isEditable={isEditable}
                      onEdit={(updatedReference) => handleEditReference(index, updatedReference)}
                      index={index}
                    />
                  ))}
                </VStack>
              </>
            ) : (
              <Text fontWeight={"light"} py="2">
                Your references have already been submitted!
              </Text>
            )}
          </VStack>
        </CardBody>
        <CardFooter justify="right">
          {isEditable && (
            <ConfirmationButton
              label="Submit"
              size={{ base: "sm", md: "md" }}
              variant="Button"
              confirmationButtonLabel="Submit"
              colorScheme="teal"
              confirmationHeader="Confirm Submission"
              children="Are you sure you'd like to submit these references?"
              onConfirm={handleSubmit}
              isDisabled={validReferencesCount < 2}
            />
          )}
        </CardFooter>
      </Card>
    </Flex>
  );
};

export default ReferencesPage;
