// Thinking eventually we will want to have a user profile page to display jobs applied to, resume, jobs saved, etc.
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AttributesTable, Header, SplitPage } from "@sciencecorp/helix-components";
import { Avatar, Box, VStack, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useUserQuery, useCurrentUserQuery } from "../api/user/queries";

export const UserProfile = () => {
  const { id } = useParams();
  if (!id) return null;
  const { data: user } = useUserQuery(+id);
  return (
    <SplitPage
      sidebarWidth="350px"
      sidebarWidthXL="450px"
      sidebar={
        <VStack height="100%" spacing={3}>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            px={6}
            py={5}
            borderRadius="md"
            width="100%">
            <Avatar size="xl" src={user?.picture_url || ""} name={user?.name} />

            <Header title={user ? user.name : "(loading...)"}></Header>
          </VStack>
          <Box
            width="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            p={4}
            borderRadius="md">
            <AttributesTable
              title="Basic Information"
              attributes={[{ label: "Email", value: user?.email }]}
            />
          </Box>
        </VStack>
      }
      main={undefined}
    />
  );
};
export default UserProfile;
