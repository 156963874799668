import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "..";
import { getUser } from "./methods";
import { MODEL_TYPE, currentUserSchema, UserApiData } from "./schema";

export const USERS_BASE_URL = "users";

export const useUserQuery = (id: number) => {
  return useQuery({
    queryKey: [USERS_BASE_URL, id],
    queryFn: () => getUser(id),
  });
};

export function useCurrentUserQuery() {
  const path = ["users", "me"];
  return useQuery(path, async () => {
    const result = await api.get(path.join("/"));
    return currentUserSchema.parse(result.data);
  });
}
