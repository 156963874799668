import React from "react";
import { ChakraProvider, ColorModeScript, extendTheme, theme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { App } from "./App";
import { Home } from "./Home";
import { ReferencesPage } from "./References";
import { UserProfile } from "./Users";

const queryClient = new QueryClient();

const chakraTheme = extendTheme({
  config: {
    useSystemColorMode: true,
    initialColorMode: "system",
  },
  fonts: {
    heading: `'Plain', sans-serif`,
    body: `'Plain', sans-serif`,
  },
});

const Main = () => (
  <ChakraProvider theme={chakraTheme}>
    <QueryClientProvider client={queryClient}>
      <ColorModeScript />
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<ReferencesPage />} />
            {/* <Route path="/references" element={<ReferencesPage />} /> */}
            <Route path="/users/:id" element={<UserProfile />} />
          </Routes>
        </App>
      </BrowserRouter>
    </QueryClientProvider>
  </ChakraProvider>
);

export default Main;
