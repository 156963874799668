import { Box, ChakraProvider, ColorModeScript, extendTheme, theme } from "@chakra-ui/react";
import type { NavItem } from "@sciencecorp/helix-components";
import { Navbar } from "@sciencecorp/helix-components";
import React, { ReactNode } from "react";

import { handleLogin, handleLogout } from "../api";
import { useCurrentUserQuery } from "../api/user/queries";

const navItems: NavItem[] = [];

export const App = (props: { children: ReactNode }) => {
  const { children } = props;
  const { data: userData } = useCurrentUserQuery();
  const userMenu = {
    ...userData,
    url: `/users/${userData?.id}`,
  };

  return (
    <>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <Navbar
          hideGlobalNav={true}
          homeUrl={"/"}
          items={navItems}
          title={"Work at Science"}
          user={userMenu}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
        <Box p={4}>{children}</Box>
      </ChakraProvider>
    </>
  );
};
