import React from "react";
import {
  FormControl,
  FormLabel,
  Box,
  useColorModeValue,
  Input,
  Textarea,
  Heading,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
} from "@chakra-ui/react";
import { ReferenceData } from "../api/reference";

type ReferenceCardProps = {
  reference: ReferenceData;
  isEditable: boolean;
  onEdit: (updatedReference: ReferenceData) => void;
  index: number;
};

export const ReferenceCard = ({ reference, isEditable, onEdit, index }: ReferenceCardProps) => {
  const handleFieldUpdate = (field: keyof ReferenceData, value: string) => {
    const updatedReference = { ...reference, [field]: value };
    onEdit(updatedReference);
  };

  const isRequired = index < 2;

  return (
    <Box alignItems="start" width="100%" bg={useColorModeValue("transparent", "gray.700")}>
      <Accordion reduceMotion allowMultiple>
        <AccordionItem>
          <AccordionButton
            borderBlockEnd={"1px"}
            borderColor={useColorModeValue("gray.300", "gray.700")}>
            <Box as="span" flex="1" textAlign="left" m={2}>
              <Heading size="md" fontWeight={"light"}>
                Reference {index + 1}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} m={2}>
            <HStack spacing={4} mb={4}>
              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Name</FormLabel>
                <Input
                  type="text"
                  defaultValue={reference.name}
                  placeholder="Name"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("name", e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Job Title</FormLabel>
                <Input
                  type="text"
                  defaultValue={reference.title}
                  placeholder="Job Title"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("title", e.target.value)}
                />
              </FormControl>
            </HStack>

            <HStack spacing={4} mb={4}>
              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Company</FormLabel>
                <Input
                  type="text"
                  defaultValue={reference.company}
                  placeholder="Company"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("company", e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Relationship</FormLabel>
                <Input
                  type="text"
                  defaultValue={reference.relationship}
                  placeholder="Relationship"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("relationship", e.target.value)}
                />
              </FormControl>
            </HStack>

            <HStack spacing={4} mb={4}>
              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Email Address</FormLabel>
                <Input
                  type="email"
                  defaultValue={reference.email}
                  placeholder="Email Address"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("email", e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired={isRequired}>
                <FormLabel fontWeight={"light"}>Phone Number</FormLabel>
                <Input
                  type="tel"
                  defaultValue={reference.phone}
                  placeholder="(123) 456-7890"
                  isReadOnly={!isEditable}
                  onChange={(e) => handleFieldUpdate("phone", e.target.value)}
                />
              </FormControl>
            </HStack>

            <FormControl mt={4}>
              <FormLabel fontWeight={"light"}>Additional Information </FormLabel>
              <Textarea
                defaultValue={reference.additional_information}
                placeholder="Enter any additional information here"
                isReadOnly={!isEditable}
                onChange={(e) => handleFieldUpdate("additional_information", e.target.value)}
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
